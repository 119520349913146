
localStorage.clear();

if (document.querySelector('input[name="produits"]')) {

    document.querySelectorAll('input[name="produits"]').forEach((elem) => {
        elem.addEventListener("change", function(event) {

            let radioValue = event.target.value;
            let getActualProduits = localStorage.getItem('produits');

            if(!getActualProduits){
                localStorage.setItem('produits', 'one');
                getActualProduits = localStorage.getItem('produits');
            }

            document.querySelector(".produits ." + radioValue ).className = "image fullBackgroundImage transition shadow " + radioValue; // Show Image
            document.querySelector(".produits .right ." + radioValue ).className = "active " + radioValue; // Show Texte

            document.querySelector(".produits .left ." + getActualProduits ).className = "image fullBackgroundImage hidden transition " + getActualProduits; //Hide Image
            document.querySelector(".produits .right ." + getActualProduits ).className = "hidden transition " + getActualProduits; //Hide Text

            localStorage.setItem('produits', radioValue);

        });
    });


}
