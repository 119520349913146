
document.addEventListener("DOMContentLoaded", function () {

    const titleTypeIf = document.getElementById('titleTypeIt');

    if(titleTypeIf){
        new TypeIt("#titleTypeIt").go();
    }


});



