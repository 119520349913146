/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */


// any CSS you import will output into a single css file (app.css in this case)

import './styles/shared/header.scss';
import './styles/shared/footer.scss';
import './styles/shared/deuxColonnes.scss';
import './styles/shared/produits.scss';
import './styles/shared/section_imageRight.scss';
import './styles/shared/section_textOnImage.scss';
import './styles/variables.scss';



import './styles/accueil.scss';
import './styles/bonnes-affaires.scss';
import './styles/oenologie.scss';
import './styles/mariage.scss';
import './styles/grossiste.scss';
import './styles/restaurant.scss';
import './styles/epicerie.scss';
import './styles/camping.scss';
import './styles/autres-activite.scss';
import './styles/event.scss';


// start the Stimulus application
import './bootstrap';

import './js/typeIt.js';
import './js/bonnes-affaires.js';
import './js/swiper.js'